<template>
    <div>
        <HeaderFive>
            <!-- <img slot="logo" src="../assets/img/xgm/icon.png" /> -->
            <img slot="logo" src="../assets/img/xgm/icon_text.png" />
        </HeaderFive>

        <!-- Start Bradcaump area -->
        <div class="bradcaump_area">
            <div class="bg-fixed bradcaump_area bg_image--12 static-breadcaump" data-black-overlay="5">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="text-center bradcaump_inner">
                                <h2 class="bradcaump-title">用户中心</h2>
                                <p>
                                    登录后可以享受所有服务...
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <!-- End Bradcaump area -->

        <!-- Start Contact Area -->
        <div class="rf-contact-area ">
            <div class="contact-wrapper">
                <v-row align="center">
                    <v-col lg="5" md="6" sm="12" cols="12">

                        <ValidationObserver v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(onSubmit)">
                                <div class="form-wrapper">
                                    <v-alert border="left" color="indigo" dark v-show="showAccontFlag">
                                        您已经登录账号:{{ account }}
                                    </v-alert>
                                    <!-- <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                        <label>
                                            <input type="text" v-model="formData.name" placeholder="用户名 *" />
                                            <span class="inpur-error">{{ errors.length > 0 ? '请输入用户名' : '' }}</span>
                                        </label>
                                    </ValidationProvider> -->
                                    <!-- <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                                        <label>
                                            <input type="text" rules="required|email" v-model="formData.email"
                                                placeholder="Your email *" />
                                            <span class="inpur-error">{{ errors[0] }}</span>
                                        </label>
                                    </ValidationProvider> -->
                                    <ValidationProvider name="phone" rules="required" v-slot="{ errors }">
                                        <label>
                                            <input type="text" v-model="formData.phone" placeholder="手机号码 *" />
                                            <span class="inpur-error">{{ errors.length > 0 ? '请输入手机号码' : '' }}</span>
                                        </label>
                                    </ValidationProvider>
                                    <ValidationProvider name="code" rules="" v-slot="{ errors }">
                                        <label>
                                            <!-- <v-otp-input v-model="formData.code" placeholder="验证码 *"></v-otp-input> -->
                                            <input type="text" v-model="formData.code" placeholder="验证码 *" />
                                            <span class="inpur-error">{{ errors.length > 0 ? '请输入验证码' : '' }}</span>
                                        </label>
                                    </ValidationProvider>

                                    <!-- <ValidationProvider name="message" rules="required" v-slot="{ errors }">
                                        <label>
                                            <textarea v-model="formData.message" placeholder="Message *"></textarea>
                                            <span class="inpur-error">{{ errors[0] }}</span>
                                        </label>
                                    </ValidationProvider> -->
                                    <v-btn @click="send()" :disabled="isButtonDisabled">
                                        {{ btnText }}
                                    </v-btn>
                                    <v-btn style="margin-top: 25px;" @click="login()">
                                        一键登录
                                    </v-btn>
                                    <!-- <button type="submit" @click="send()">
                                        <span>1.验证码</span>
                                        <svg fill="#000" width="25" height="8" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M44.102 4l-2.444-2.445.009-1.405 4.325 4.325-4.38 4.38.01-1.423L44.101 5H.002V4z"
                                                fill-rule="evenodd"></path>
                                        </svg>
                                    </button> -->

                                    <!-- <button type="submit" style="margin-top: 25px;">
                                        <span>2.登录</span>
                                        <svg fill="#000" width="25" height="8" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M44.102 4l-2.444-2.445.009-1.405 4.325 4.325-4.38 4.38.01-1.423L44.101 5H.002V4z"
                                                fill-rule="evenodd"></path>
                                        </svg>
                                    </button> -->
                                </div>
                            </form>
                        </ValidationObserver>
                        <div class="form-output">
                            <p class="form-messege-active"></p>
                        </div>
                    </v-col>
                    <v-col lg="7" md="6" sm="12" cols="12">
                        <div class="contact-address">
                            <h2 class="heading-title">通讯方式</h2>
                            <div class="address" v-for="(address, i) in addressContent" :key="i">
                                <i class="icon" v-html="iconSvg(address.icon)"></i>
                                <div class="content">
                                    <p v-if="address.isAddress">
                                        {{ address.desc1 }} <br />
                                        {{ address.desc2 }}
                                    </p>
                                    <p v-if="address.isNumber">
                                        <a :href="address.to">{{ address.num1 }}</a>
                                    </p>
                                    <p v-if="address.isNumber">
                                        <a :href="address.to">{{ address.num2 }}</a>
                                    </p>
                                    <p v-if="address.isMail">
                                        <a :href="`mailto:${address.to}`">{{ address.mail }}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <!-- End Contact Area -->

        <!-- Start Google MAp -->
        <!-- End Google MAp -->
        <Footer />

        <v-snackbar v-model="snackbar" :timeout="timeout" :color="this.color">
            {{ text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
                    关闭
                </v-btn>
            </template>
        </v-snackbar>

    </div>

</template>

<script>
// import HeaderTwo from "../components/header/HeaderTwo";
import HeaderFive from "../components/header/HeaderFive";
// import Footer from "../components/footer/Footer";
import Footer from "../components/footer/FooterTwo";
import feather from "feather-icons";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import api from "../api/api";
export default {
    components: {
        HeaderFive,
        Footer,
        ValidationObserver,
        ValidationProvider,
    },
    data() {
        return {
            formData: {
                name: "",
                phone: "",
                code: "",
            },
            addressContent: [
                {
                    icon: "map-pin",
                    desc1: "浙江省宁波市鄞州区潘火路",
                    desc2: " 215号605-2室",
                    isAddress: true,
                },
                {
                    icon: "smartphone",
                    num1: "+086 15258355440",
                    num2: "+086 13484397633",
                    to: "tel:15258355440",
                    isNumber: true,
                },
                {
                    icon: "mail",
                    mail: "3226700793@qq.com",
                    to: "mailto:3226700793@qq.com",
                    isMail: true,
                },
            ],
            snackbar: false,
            text: '',
            timeout: 3000,
            color: 'pink',
            isButtonDisabled: false,
            countdownTime: 60,
            btnText: '获取验证码',
            account: '',
            showAccontFlag: false
        };
    },
    mounted() {
        this.showAccount()
    },
    beforeDestroy() {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval); // 组件销毁前清除计时器
        }
    },
    methods: {
        iconSvg(icon) {
            return feather.icons[icon].toSvg();
        },
        onSubmit() {
            console.log(this.formData);
        },
        send() {
            let phone = this.formData.phone
            if (phone == "") {
                this.showError('请输入手机号')
                return;
            }
            api.get('/center/send?phone=' + phone)
                .then(response => {
                    // 处理响应数据
                    const data = response.data
                    console.log(response)
                    if (data.code != '200') {
                        this.showError(data.message);
                        return;
                    }
                    if (data.code == '200') {
                        this.showSuccess("验证码发送成功");
                        this.startCountdown()
                    }
                })
                .catch(error => {
                    // 处理错误
                    console.log(error)
                    this.showError('发生网络错误，请稍后再试');
                    return;
                });
        },
        login() {
            let phone = this.formData.phone
            let code = this.formData.code
            if (phone == "") {
                this.showError('请输入手机号')
                return;
            }
            if (code == "") {
                this.showError('请输入验证码')
                return;
            }
            const param = {
                'phone': phone,
                'code': code
            }
            api.get('/center/login', param)
                .then(response => {
                    // 处理响应数据
                    const data = response.data
                    if (data.code != '200') {
                        this.showError(data.message)
                        return;
                    } else {
                        const token = data.data
                        // session会话中存储信息
                        console.log(token)
                        const user = {
                            'phone': phone,
                            'token': token
                        }
                        // 将对象转换为字符串以存储在sessionStorage中
                        localStorage.setItem('user', JSON.stringify(user));
                        this.showSuccess("登录成功")
                        this.showAccount()
                    }
                    //  console.log(data)
                })
                .catch(error => {
                    // 处理错误
                    console.log(error)
                    this.showError('发生网络错误，请稍后再试');
                    return;
                });
        },
        showError(message) {
            this.color = 'pink';
            this.text = message;
            this.snackbar = true;
        },
        showSuccess(message) {
            this.color = 'green'
            this.text = message;
            this.snackbar = true;
        },
        startCountdown() {
            this.isButtonDisabled = true; // 禁用按钮
            this.countdownInterval = setInterval(() => {
                if (this.countdownTime > 0) {
                    this.btnText = '倒计时:' + this.countdownTime;
                    this.countdownTime--; // 更新倒计时时间
                } else {
                    clearInterval(this.countdownInterval); // 清除计时器
                    this.isButtonDisabled = false; // 重新启用按钮
                    this.countdownTime = 60; // 重置倒计时时间
                    this.btnText = '获取验证码';
                }
            }, 1000); // 每秒更新一次
        },
        showAccount() {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user != null) {
                const token = user.token;
                api.get('/center/check?token=' + token).then(response => {
                    const data = response.data;
                    if (data.code == 200) {
                        this.account = user.phone;
                        this.showAccontFlag = true;
                    }else{
                        this.account = '已失效';
                        this.showAccontFlag = true;
                        localStorage.clear();
                    }
                })
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.inpur-error,
.inpur-success {
    display: block;
    margin-top: 5px;
    font-size: 14px;
}

.inpur-error {
    color: #f10;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 800px;
    width: 100%;

    iframe {
        width: 100%;
        height: 100%;
    }
}

.mapouter {
    position: relative;
    width: 100%;
    height: 100%;
}

.bg_image--12 {
    background: url("../assets/img/xgm/user_banner.png");
    background-size: cover;
}
</style>